
import { Options, Vue } from "vue-class-component";
// @ts-ignore
import { VueFinalModal } from 'vue-final-modal'
import { ExclamationIcon, CheckIcon } from '@heroicons/vue/outline'
import { Prop } from "vue-property-decorator";

@Options({
  name: "Modal",
  inheritAttrs: false,
  emits: ['cancel', 'confirm'],
  components: {
    VueFinalModal,
    ExclamationIcon,
    CheckIcon
  }
})
export default class Modal extends Vue {   
  @Prop({ type: Boolean, default: true}) destructive: Boolean = true
  @Prop({ type: String, default: "Ok" }) confirmTitle: String = "Ok"
  @Prop({ type: Boolean, default: true }) showCancel: Boolean = true

}

