
import { UserAPI } from '@/api';
import { LockClosedIcon } from '@heroicons/vue/solid'
import { Options, Vue } from 'vue-class-component'
import { ExclamationIcon } from '@heroicons/vue/outline'
import { useRoute } from 'vue-router';
import Modal from '@/components/Modal.vue'
import Loader from '@/components/Loader.vue'
@Options({
  components: {
    LockClosedIcon,
    Modal,
    ExclamationIcon,
    Loader
  }
})
export default class ResetPassword extends Vue {
  
  password: string = ''
  re_password: string = ''
  
  loading: boolean = false
  validationError: string | null = null
  success: boolean = false
  error: boolean = false

  private token: string | null = null

  mounted() {
    const route = useRoute();
    this.token = route.params.token as string
  }

  resetPressedPressed() {
    
    this.error = false
    if (!this.token) {
      return
    }

    if (!this.password || !this.re_password) {
      //Missing one 
      this.validationError = "Please enter both passwords"
      return
    }

    if (this.password != this.re_password) {
      //Not matching
      this.validationError = "Passwords do not match"
      return
    }

    if (this.password.length < 10) {
      //Too short
      this.validationError = "Please enter a password at least 10 characters long"
      return
    }

    this.validationError = null
    this.loading = true

    UserAPI.resetAppUserPassword(this.token, this.password)
    .then(() => {
      this.loading = false
      this.success = true    
    })
    .catch((error) => {
      console.error(error)
      this.loading = false
      this.success = false
      this.error = true
    })
  }

  modalCancel() {
    this.error = false
  }

}
