
import { ListCellConfiguration } from '@/types/types';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator'
import { ChevronRightIcon } from '@heroicons/vue/solid'

@Options({
  components: {
    ChevronRightIcon
  }
})
export default class ListCell extends Vue {

  @Prop({ type: Boolean, default: false }) readonly squareImage!: Boolean

  @Prop({ require: true }) readonly configuration!: ListCellConfiguration

  applyImageStyle() {
    return {
      'background-image': 'url(' + this.configuration.imageURL + ')'
    }
  }


}
