
import { Options, Vue} from 'vue-class-component'
import Button from '@/components/Button.vue'
import Modal from '@/components/Modal.vue'
import {
  SpeakerphoneIcon
} from '@heroicons/vue/outline'
import { AdminAPI } from '@/api'
import Loader from '@/components/Loader.vue'

@Options({
  components: {
    Button,
    SpeakerphoneIcon,
    Modal,
    Loader
  }
})
export default class AppPushNotifications extends Vue {

  private showModal = false
  private pushText = ""
  private loading = false

  sendNotification() {
    this.showModal = true
  }

  async modalConfirm() {
    this.showModal = false
    const appId = this.$route.params.appId as string
    this.loading = true
    try {
      await AdminAPI.sendNotificationToAllUsers(appId, this.pushText)
      this.pushText = ""
    } catch (err) {
      alert("Failed to send notification");
    } finally {
      this.loading = false
    }

  }

  modalCancel() {
    this.showModal = false
  }
}
