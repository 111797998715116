

import Routes from '@/router/routes'
import { RouteRecordRaw } from 'vue-router'
import AppDashboard from '@/views/app/tabs/AppDashboard.vue'
import AppContestants from '@/views/app/tabs/AppContestants.vue'
import AppJudges from '@/views/app/tabs/AppJudges.vue'
import AppMentors from '@/views/app/tabs/AppMentors.vue'
import AppPushNotification from '@/views/app/tabs/AppPushNotification.vue'
import AppUsers from '@/views/app/tabs/AppUsers.vue'
import AppVotingWindows from '@/views/app/tabs/AppVotingWindows.vue'
import AppRounds from '@/views/app/tabs/AppRounds.vue'
import AppRoundResult from '@/views/app/tabs/AppRoundResult.vue'


const AppChildRoutes: RouteRecordRaw[] = [
      {
        path: '/app/:appId/dashboard',
        name: Routes.AppTabRoutes.Dashboard,
        component: AppDashboard
      },
      {
        path: '/app/:appId/contestants',
        name: Routes.AppTabRoutes.Contestants,
        component: AppContestants,
      },
      {
        path: '/app/:appId/judges',
        name: Routes.AppTabRoutes.Judges,
        component: AppJudges,
      },
      {
        path: '/app/:appId/mentors',
        name: Routes.AppTabRoutes.Mentors,
        component: AppMentors,
      },
      {
        path: '/app/:appId/pushnotification',
        name: Routes.AppTabRoutes.PushNotification,
        component: AppPushNotification,
      },
      {
        path: '/app/:appId/users',
        name: Routes.AppTabRoutes.Users,
        component: AppUsers,
      },
      {
        path: '/app/:appId/rounds',
        name: Routes.AppTabRoutes.Rounds,
        component: AppRounds,
      },
      {
        path: '/app/:appId/rounds/:roundId/result',
        name: Routes.AppTabRoutes.RoundResults,
        component: AppRoundResult,
      },
      // {
      //   path: '/app/:appId/votingwindows',
      //   name: Routes.AppTabRoutes.VotingWindows,
      //   component: AppVotingWindows,
      // },

]

export default AppChildRoutes 