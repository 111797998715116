
import { LockClosedIcon } from '@heroicons/vue/solid'
import Routes, { AppRoutes } from '@/router/routes'
import { Options, Vue } from 'vue-class-component'
import { UserAPI } from '@/api'
import Loader from '@/components/Loader.vue'

@Options({
  components: {
    LockClosedIcon,
    Loader
  }
})
export default class Login extends Vue {

  emailAddress: string = ''
  password: string = ''
  
  showForgotPassword = false
  loading: boolean = false
  error: boolean = false

  async loginPressed() {
    this.loading = true
    this.error = false

    try {
      const user = (await UserAPI.login(this.emailAddress, this.password)).data
      if (user.defaultApp) {
        Routes.go(AppRoutes.AppBase, { params: {appId: user.defaultApp}})
      } else {
        Routes.go(AppRoutes.AppSelect)
      }
    } catch (err) {
      console.error(err)
      this.error = true
    } finally {
      this.loading = false
    }
    
  }

  forgotPasswordPressed() {
    //TODO: Go to Forgot password
  }
}
