
import { Options, Vue } from "vue-class-component";
// @ts-ignore
import { VueFinalModal } from 'vue-final-modal'
import { ClipboardCheckIcon } from '@heroicons/vue/outline'

// @ts-ignore
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Loader from "./Loader.vue";
import { Prop, Watch } from "vue-property-decorator";
import { Contestant, VotingWindow } from "@/types/types";
import { AdminAPI } from "@/api";
import moment from 'moment'

@Options({
  name: "AddPhoneVotesModal",
  emits: ['cancel', 'confirm'],
  components: {
    VueFinalModal,
    ClipboardCheckIcon,
    flatPickr,
    Loader
  }
})
export default class AddPhoneVotesModal extends Vue {   

    private numberOfVotes: string | null = null
    private loading = false

    @Prop({required: true}) votingWindow!: VotingWindow
    @Prop({required: true}) contestant!: Contestant


    @Watch('votingWindow')
    updateParams() { 
      this.numberOfVotes = null
    }

    created() {
      this.updateParams()
    }

    get title() {
      const openTimeFormatted = moment(this.votingWindow.openingTime).format('MMMM Do, HH:mm:ss')
      const closingimeFormatted = moment(this.votingWindow.closingTime).format('Do, HH:mm:ss')
      return `Add phone votes for ${this.contestant.firstName} for vote window ${openTimeFormatted} -> ${closingimeFormatted}` 
    }

    async savePressed(close: any) {

        const votesString = this.numberOfVotes

        if (!votesString) {
          return;
        }

        const votesValue = parseInt(votesString)
        if (votesValue.toString() != votesString) {
          alert("Please enter valid number of votes");
          return
        }

  
        this.loading = true
        const appId = this.$route.params.appId as string
        try {
          await AdminAPI.addPhoneVotesToWindow(appId, this.contestant.id, this.votingWindow.id, votesValue)
          this.$emit('confirm', close)
        } catch (error) {
            console.log(error)
            alert("Failed to add phone votes");
        } finally {
          this.loading = false
        }        
    }
}   

