
import { Options, Vue } from "vue-class-component";
import { Prop } from 'vue-property-decorator'
import AttributeEditCreateDetailsComponent from '@/components/AttributeEditCreateDetailsComponent.vue'
import { User } from "@/types/types";
import { AttributeState, AttributeType, ChildObjectAttributes } from "@/types/attributes";
import moment from "moment";


@Options({
  name: "AppUserDetailComponent",
  components: {
    AttributeEditCreateDetailsComponent
  },
  emits: ['detailsComponentDidComplete', 'update:drawerDismissable', 'dismissSlideOver']
})
export default class AppUserDetailComponent extends Vue {   

  @Prop( {required: true} ) userToView!: User
  @Prop({type: Boolean, default: true }) drawerDismissable: Boolean = true
    
  private loading: boolean = false
  private error: boolean = false

  get titleText() {
    return "User"
  }

  get subtitleText() {
    return this.userToView.email ?? this.userToView.fbId ?? this.userToView.appleId ?? this.userToView.id
  }


  getAttributesForContestant(): AttributeState[] {

      const purchasesChildAttributes: ChildObjectAttributes[] = this.userToView.purchases.map( purchase => {

        const formattedPurchasesCount = new Intl.NumberFormat('en-IE', { style: 'currency', currency: 'EUR' }).format(purchase.price / 100)

        return {
          id: purchase.id,
          attributes: [
            {title: "Votes", value: purchase.votes.toString()},
            {title: "Price", value: formattedPurchasesCount},
            {title: "Locale", value: purchase.localeId},
            {title: "Transaction ID", value: purchase.transactionId},
            {title: "Date", value: this.formatDate(purchase.createdAt)}
          ]
        }
      }) ?? []

      return [
          {type: AttributeType.TEXT, required: false, title: "Email", value: this.userToView.email, placeholder: "", key: "email"},
          {type: AttributeType.TEXT, required: false, title: "Facebook Id", value: this.userToView.fbId, placeholder: "", key: "fbId"},
          {type: AttributeType.TEXT, required: false, title: "Apple Id", value: this.userToView.appleId, placeholder: "", key: "appleId"},
          {type: AttributeType.TEXT, required: false, title: "Age", value: this.userToView.age, placeholder: "", key: "age"},
          {type: AttributeType.TEXT, required: false, title: "Sex", value: this.userToView.sex, placeholder: "", key: "sex"},
          {type: AttributeType.TEXT, required: false, title: "Location", value: this.userToView.location, placeholder: "", key: "location"},
          {type: AttributeType.TEXT, required: false, title: "Remaining Votes", value: this.userToView.remainingVotes.toString(), placeholder: "", key: "remainingVotes"},
          {type: AttributeType.TEXT, required: false, title: "Deleted Account", value: (this.userToView.deletedAccount ?? false).toString(), placeholder: "", key: "deletedAccount"},

          {type: AttributeType.CHILD_OBJECTS, required: false, title: "Purchases", value: [], placeholder: "Purchasses Windows", key: "purchases", childObjectAttributes: purchasesChildAttributes},
          
      ]
  }
    
  formatDate(date: string) {
    return moment(date).format('MMMM Do YYYY, HH:mm:ss')
  }
}

