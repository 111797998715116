import Vue from 'vue'
import router from '@/router';

export enum StandardRoutes {
  // Outside of app
  Home = 'home',
  Login = 'login',
  ResetPassword = 'resetPassword',
  PrivacyPolicy = 'privacyPolicy',
  PrivacyPolicyIrish = 'privacyPolicyIrish'
}

export enum AppRoutes {
  AppBase = 'AppBase',
  AppSelect = 'AppSelect',
  AppCreate = 'AppCreate'
  // Add app, other full screen app routes
}

export enum AppTabRoutes {
  Dashboard = 'dashboard',
  Users = 'users',
  Contestants = 'contestants',
  PushNotification = 'pushnotification',
  Mentors = 'mentors',
  Judges = 'judges',
  VotingWindows = 'votingWindows',
  Rounds = "rounds",
  RoundResults = "roundResults"
}


type AllRoutes = StandardRoutes | AppRoutes | AppTabRoutes 

export default class Routes {

  static readonly Standard = StandardRoutes;
  static readonly AppRoutes = AppRoutes;
  static readonly AppTabRoutes = AppTabRoutes;
  

  static currentRoute: AllRoutes = StandardRoutes.Home;
   
  static go(route: AllRoutes, data: any = null) {

    if (data) {
      const dataToSend: any = { name: route }
      if (data.props) { dataToSend.props = data.props }
      if (data.query) { dataToSend.query = data.query }
      if (data.params) { dataToSend.params = data.params }    

      router.push(dataToSend)
      .then(() => this.currentRoute = route)
      .catch((error) => {
        console.log(error)
      })
    } else {
      if (this.currentRoute !== route) {
        const newRoute = route as string
        router.push({ name: newRoute })
        .then(() =>{
          this.currentRoute = route
        })
        .catch((error)=> {
            console.log(error)
        })
      }
    }
    
  }

  static redirect(route: string) {
    router.push({ path: route })
    .catch((error) => {})
  }

  static clearQuery() {
    router.push({ query: {} }).catch((error) => {})
  }

  static updateCurrentRoute(newRoute: string) {
    const route = newRoute as AllRoutes
    if (route) {
      this.currentRoute = route
    }
  }

}