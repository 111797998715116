
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XIcon } from '@heroicons/vue/outline'
import { Prop } from 'vue-property-decorator'
import { Options, Vue } from 'vue-class-component'

@Options({
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon
  }
})
export default class SlideOverComponent extends Vue {

  @Prop({type: Boolean, default: false }) open: Boolean = false
  @Prop({type: Boolean, default: true }) drawerDismissable: Boolean = true
  @Prop({type: String, default: "" }) title!: string

  shouldClose() {  
    if (this.drawerDismissable) {
      this.$emit('update:open', false)
    }
  }

}
