
import { AdminAPI } from '@/api'
import { ListCellAttributes, ListCellConfiguration, User } from '@/types/types'
import { Options, Vue} from 'vue-class-component'
import Button from '@/components/Button.vue'
import ListComponent from '@/components/ListComponent.vue'

import {
  SearchIcon
} from '@heroicons/vue/outline'
import SlideOverComponent from '@/components/SlideOverComponent.vue'
import AppUserDetailComponent from '@/components/AppUserDetailComponent.vue'

@Options({
  components: {
    Button,
    SearchIcon,
    ListComponent,
    SlideOverComponent,
    AppUserDetailComponent
  }
})
export default class AppUsers extends Vue {
  
  private loading: Boolean = false
  private error: Boolean = false

  private listConfig: ListCellConfiguration[] | null = null
  private openDrawer = false
  private drawerDismissable = true
  private userToView: User | null = null

  private queryText: string = ""


  async searchForQuery() {
    const appId = this.$route.params.appId as string
    try {
      this.loading = true
      const searchResults = (await AdminAPI.searchForUsers(appId, this.queryText)).data
      this.mapToListConfig(searchResults.users)
      this.loading = false
    } catch (err) {
      this.loading = false
      this.error = true
    }
  }

  mapToListConfig(users: User[]) {

    this.listConfig = users.map( user => {

      const attrs: ListCellAttributes[] = []

      const purchasesCount = user.purchases.reduce((travelingTotal, purchase) => travelingTotal + purchase.price, 0)
      const votesCount = user.votes.length
      const votesRemaining = user.remainingVotes


      const formattedPurchasesCount = new Intl.NumberFormat('en-IE', { style: 'currency', currency: 'EUR' }).format(purchasesCount / 100)

      attrs.push({title: "Votes remaining", value: votesRemaining.toString()})
      attrs.push({title: "Votes cast", value: votesCount.toString()})
      attrs.push({title: "Purchases", value: formattedPurchasesCount})

      let loginType = '';
      if (user.appleId) {
        loginType = "Sign in With Apple"
      } else if (user.fbId) {
        loginType = "Facebook"
      } else {
        loginType = "Email Password"
      }


      return {
        title: user.email ?? user.fbId ?? user.appleId ?? "No information found",
        subtitle: `Account type: ${loginType}`,
        imageURL: undefined,
        attributes: attrs,
        item: user
      }
    })
  }

  itemPressed(payload: any) {
    const user = payload.config.item as User
    this.userToView = user
    this.openDrawer = true
  }

  didCompleteDetailsComponent() {
    this.openDrawer = false
    this.userToView = null
  }

}
