
import { App } from '@/types/types'
import { Options, Vue} from 'vue-class-component'

import {
  PlusIcon
} from '@heroicons/vue/outline'
import { CalendarIcon, ChevronRightIcon } from '@heroicons/vue/solid'

import Routes, { AppRoutes } from '@/router/routes'
import moment from 'moment'

@Options({name: 'AppSelect',
    components:{
      PlusIcon,
      ChevronRightIcon,
      CalendarIcon
    }
})
export default class AppSelect extends Vue {
  
  private options: {title: string, subtitle?: string, app?: App, key: string }[] = []

  mounted() {
    const userApps = this.$store.state.user.user?.apps ?? []

    this.options = userApps.map( app => {
        return { 
          title: app.name,
          subtitle: this.formattedDateForApp(app),
          app: app,
          key: ""+app.id
        }
    })

    this.options.push( {
      title: 'Create new season',
      key: "create_app_key"
    })

  }

  didSelectOption(index: number) {
    const option = this.options[index]
    if (option.app) {
      this.didSelectApp(option.app)
    } else {
      this.createAppPressed()
    }
  }

  createAppPressed() {
    Routes.go(AppRoutes.AppCreate)
  }

  didSelectApp(app: App) { 
    Routes.go(AppRoutes.AppBase, {
      params: {appId: app.appId}
    })
  }

  formattedDateForApp(app: App) {
    return moment(app.createdAt).format('MM/DD/YYYY')
  }

}
