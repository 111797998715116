
import { AdminAPI } from '@/api'
import { AttributeMode, Contestant, ListCellAttributes, ListCellConfiguration } from '@/types/types'
import { Options, Vue} from 'vue-class-component'
import ListComponent from '@/components/ListComponent.vue'
import { PlusIcon } from '@heroicons/vue/solid'
import SlideOverComponent from '@/components/SlideOverComponent.vue'
import ContestantEditComponent from '@/components/ContestantEditComponent.vue'
import Button from '@/components/Button.vue'

@Options({
  components: {
    ListComponent,
    PlusIcon,
    Button,
    SlideOverComponent,
    ContestantEditComponent
  }
})
export default class AppContestants extends Vue {

  private loading: Boolean = false
  private error: Boolean = false

  private listConfig: ListCellConfiguration[] = []
  private openDrawer = false
  private drawerDismissable = true
  private attributeMode: AttributeMode = AttributeMode.CREATE
  private contestantToView: Contestant | null = null

  mounted() {
    this.loadData()
  }

  async loadData() {
    const appId = this.$route.params.appId as string
    try {
      this.loading = true
      const contestants = (await AdminAPI.getContestantsForAppId(appId)).data
      this.mapToListConfig(contestants)
      this.loading = false
    } catch (err) {
      this.loading = false
      this.error = true
    }
  }

  mapToListConfig(contestants: Contestant[]) {

    this.listConfig = contestants.map( contestant => {

      const attrs: ListCellAttributes[] = []

      attrs.push({title: "Description", value: contestant.description})
      attrs.push({title: "Description Irish", value: contestant.descriptionIrish})

      attrs.push({title: "Twitter", value: contestant.twitterHandle ?? "N/A"})

      return {
        title: contestant.name,
        subtitle: contestant.location,
        imageURL: contestant.profilePhoto,
        attributes: attrs,
        item: contestant
      }
    })

  }

  goToCreateContestant() {
    this.contestantToView = null
    this.attributeMode = AttributeMode.CREATE
    this.openDrawer = true
  }

  itemPressed(payload: any) {
    const contestant = payload.config.item as Contestant
    this.attributeMode = AttributeMode.VIEW_EDIT
    this.contestantToView = contestant
    this.openDrawer = true
  }

  didCompleteDetailsComponent() {
    this.openDrawer = false
    this.contestantToView = null
    this.loadData()
  }

}
