
import { Options, Vue} from 'vue-class-component'
import RoundDropdownMenu from '@/components/RoundDropdownMenu.vue'
import { Contestant, DropDownOption, GraphOptions, GraphSeries, Round, VoteResults, VotingWindow } from '@/types/types'
import { AdminAPI } from '@/api'
import { Watch } from 'vue-property-decorator'
import { ExclamationIcon } from '@heroicons/vue/solid'
import moment from 'moment'

import Loader from '@/components/Loader.vue'
import Button from '@/components/Button.vue'
import { PhoneIcon } from '@heroicons/vue/outline'
import AddPhoneVotesModal from '@/components/AddPhoneVotesModal.vue'

@Options({
  components: {
    RoundDropdownMenu,
    Loader,
    Button,
    ExclamationIcon,
    PhoneIcon,
    AddPhoneVotesModal
  }
})
export default class AppDashboard extends Vue {
  
  private loading: Boolean = false
  private error: Boolean = false

  private selectedRound: Round | null = null
  private loadingResults: Boolean = false
  private resultserror: Boolean = false

  private roundOptions: DropDownOption[] = []
  private selectedOption: DropDownOption | null = null
  
  private roundResults: VoteResults | null = null

  private contestantMap: {[key: number]: Contestant} = {}
  private windowMap: {[key: number]: VotingWindow} = {}

  private graphOptions: GraphOptions | null = null
  private graphSeries: GraphSeries[] = []

  private showPhoneVotesModal:boolean = false
  private selectedContestant: Contestant | null = null
  private selectedWindow: VotingWindow | null = null

  @Watch('$route')
  routeDidChange() {
    console.log("App Round did change")
    this.loadAppData()
  }

  async mounted() {
    this.loadAppData()
  }

  async loadAppData() {
    const appId = this.$route.params.appId as string
    this.error = false
    try {
      this.loading = true
      const rounds = (await AdminAPI.getRoundsForAppId(appId)).data

      this.roundOptions = rounds.map( (round) => {
        return {
          title: round.name,
          object: round,
          key: round.id.toString()
        }
      })
      if (this.roundOptions.length) {
        this.selectedOption = this.roundOptions[0]
      }

      this.loading = false
    } catch (err) {
      this.loading = false
      this.error = true
    }
  }

  async didSelectRound(round: Round) {
    this.selectedRound = round
    this.resultserror = false
    try {
      this.roundResults = null
      this.loadingResults = true
      const appId = this.$route.params.appId as string
      this.roundResults = (await AdminAPI.getVoteResultForRound(appId, round.id)).data

      this.roundResults.round.contestants.forEach((contestant) => {
        this.contestantMap[contestant.id] = contestant
      })

      this.roundResults.round.votingWindows.forEach((window) => {
        this.windowMap[window.id] = window
      })

      this.formulateGraphDataFromResult()
    } catch (err) {
      console.error(err)
      this.resultserror = true
    } finally {
      this.loadingResults = false
    }

  }

  formulateGraphDataFromResult() {

    let contestantNames: string[] = []
    let totalVotesForContestants: number[] = []

    this.roundResults?.contestantResults.forEach( contestantRes => {

      contestantNames.push(this.contestantMap[contestantRes.contestant].name)
      
      const totalVotes = contestantRes.votesPerWindow.reduce((travelingTotal, voteResults) => travelingTotal + voteResults.totalVotes, 0)
      totalVotesForContestants.push(totalVotes)
    } )


    this.graphOptions = {
        chart: {
          id: 'round-results'
        },
        xaxis: {
          // Contestants
          categories: contestantNames
        }
    }

    this.graphSeries = [
      {
        name: 'Total Votes',
        //Totals
        data: totalVotesForContestants
      }
    ]

  }

  retryPressed() {
    if (this.error) {
      this.loadAppData()
    } else if (this.selectedRound){
      this.didSelectRound(this.selectedRound)
    }
  }

  voteWindowTitle(windowId: number) {
    const window = this.windowMap[windowId]
    const openTimeFormatted = moment(window.openingTime).format('MMMM Do YYYY, HH:mm:ss')
    const closingimeFormatted = moment(window.closingTime).format('MMMM Do YYYY, HH:mm:ss')

    return openTimeFormatted + " -> " + closingimeFormatted
  }



  addPhoneVotes(windowId: number, contestantId: number) {
    this.selectedWindow = this.windowMap[windowId]
    this.selectedContestant = this.contestantMap[contestantId]
    this.showPhoneVotesModal = true
  }

modalCancel(close: any) {
    close();
  }

  didUpdatePhoneVotes(close: any) {
    
    close();
    if(this.selectedRound) {
      this.didSelectRound(this.selectedRound)
    }
  }

}
