import { AxiosInstance } from 'axios'

export default class BaseApi {

  api: AxiosInstance
  
  constructor(parentApi: AxiosInstance) {
    
    if (!parentApi) {
      throw new Error('Missing a required paramater.');
    }

    this.api = parentApi;
  }

  baseURL() {
    const url = process.env.VUE_APP_WEB_SERVER ? process.env.VUE_APP_WEB_SERVER : 'http://localhost:1337'
    return `${url}`
  }

}