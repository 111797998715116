import Vue from 'vue'
import axios from 'axios'

import AdminAPI from '@/api/sub/admin-api'
import UserAPI from './sub/user-api';

const api = axios.create({
  withCredentials: true,
});

// api.interceptors.response.use(
//   (response) => {
//     return Promise.resolve(response);
//   },
//   (error: any) => {


//     if (error && error.response && error.response.status) {
      
//       // the user is unauthorised so we kick them
//       if (error.response.status === ErrorCode.ERROR_UNAUTHORIZED) {
//         Vue.prototype.$alerts.hideAlerts();
//         Vue.prototype.$alerts.notify({
//           title: 'Sorry',
//           message: 'We need to you to log in again',
//           error: true,
//         })

//         store.dispatch('logout')

//       }

//     }

//     Vue.prototype.$log.error(error.message.toLowerCase())

//     return Promise.reject(error);

//   },
// );

const adminAPI = new AdminAPI(api);
const userAPI = new UserAPI(api);

// // Run a healthcheck, failure will redirect the user to an error message
// miscAPI.healthCheck()
// .catch((error) => {
//   if (error && (typeof error.response === 'undefined')) {
//     Routes.go(StandardRoutes.NetworkError)
//   }
//   Vue.prototype.$log.error(error)
// })

export {
  api as API,
  adminAPI as AdminAPI,
  userAPI as UserAPI
}
