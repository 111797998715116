
import { AdminAPI } from '@/api'
import { Mentor, ListCellAttributes, ListCellConfiguration, AttributeMode } from '@/types/types'
import { Options, Vue} from 'vue-class-component'
import ListComponent from '@/components/ListComponent.vue'
import { PlusIcon } from '@heroicons/vue/solid'
import SlideOverComponent from '@/components/SlideOverComponent.vue'
import MentorEditComponent from '@/components/MentorEditComponent.vue'
import Button from '@/components/Button.vue'
@Options({
  components: {
    ListComponent,
    PlusIcon,
    Button,
    SlideOverComponent,
    MentorEditComponent
  }
})
export default class AppMentors extends Vue {
  private loading: Boolean = false
  private error: Boolean = false

  private listConfig: ListCellConfiguration[] = []
  private openDrawer = false
  private drawerDismissable = true
  private attributeMode: AttributeMode = AttributeMode.CREATE
  private mentorToView: Mentor | null = null

  mounted() {
    this.loadData()
  }

  async loadData() {
    const appId = this.$route.params.appId as string
    try {
      this.loading = true
      const mentors = (await AdminAPI.getMentorsForAppId(appId)).data
      this.mapToListConfig(mentors)
      this.loading = false
    } catch (err) {
      this.loading = false
      this.error = true
    }

  }

  mapToListConfig(mentors: Mentor[]) {

    this.listConfig = mentors.map( mentor => {

      const attrs: ListCellAttributes[] = []

      attrs.push({title: "Description", value: mentor.description})
      attrs.push({title: "Description Irish", value: mentor.descriptionIrish})

      return {
        title: mentor.name,
        imageURL: mentor.profilePhoto,
        attributes: attrs,
        item: mentor
      }
    })

  }

  goToCreateMentor() {
    this.mentorToView = null
    this.attributeMode = AttributeMode.CREATE
    this.openDrawer = true
  }

  itemPressed(payload: any) {
    const mentor = payload.config.item as Mentor
    this.attributeMode = AttributeMode.VIEW_EDIT
    this.mentorToView = mentor
    this.openDrawer = true
  }

  didCompleteDetailsComponent() {
    this.openDrawer = false
    this.mentorToView = null
    this.loadData()
  }


}
