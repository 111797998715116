import BaseAPI from '@/api/sub/base-api'
import { ContestantPayload, CreateFileUploadPayload, JudgePayload, MentorPayload, RoundPayload, RoundResultPayload, VotingWindowPayload } from '@/types/payloads'

import { 
  App,
  Contestant, FileUploadResponse, Judge, Mentor, Round, RoundResult, SearchUserResponse, VoteResults, VotingWindow,
} from '@/types/types'
import { AxiosResponse } from 'axios'
// import { ErrorCode, BillingBasis } from '@/enums';

export default class AdminAPI extends BaseAPI {

  /**
   * Create new App for user
   *
   * @returns {Promise<App>}
   * @memberof AdminAPI
   */
   async createApp(appName: string): Promise<AxiosResponse<App>> {
    return this.api.post<App>(`${this.baseURL()}/apps`, {appName: appName})
  }

  /**
   * Gets contestants for appId
   *
   * @returns {Promise<Contestant[]>}
   * @memberof AdminAPI
   */
  async getContestantsForAppId(appId: string): Promise<AxiosResponse<Contestant[]>> {
    return this.api.get<Contestant[]>(`${this.baseURL()}/${appId}/contestants`)
  }

  /**
   * Create contestant
   *
   * @returns {Promise<Contestant>}
   * @memberof AdminAPI
   */
  async createContestantsForAppId(appId: string, payload: ContestantPayload): Promise<AxiosResponse<Contestant>> {
      return this.api.post<Contestant>(`${this.baseURL()}/${appId}/contestants`, payload)
    }

  /**
   * Update contestant
   *
   * @returns {Promise<Contestant>}
   * @memberof AdminAPI
   */
  async updateContestantsForAppId(appId: string, contestantId: number, payload: ContestantPayload): Promise<AxiosResponse<Contestant>> {
    return this.api.put<Contestant>(`${this.baseURL()}/${appId}/contestants/${contestantId}`, payload)
  }

  /**
   * Gets mentors for appId
   *
   * @returns {Promise<Mentor[]>}
   * @memberof AdminAPI
   */
     async getMentorsForAppId(appId: string): Promise<AxiosResponse<Mentor[]>> {
      return this.api.get<Mentor[]>(`${this.baseURL()}/${appId}/mentors`)
    }

  /**
   * Gets judges for appId
   *
   * @returns {Promise<Judge[]>}
   * @memberof AdminAPI
   */
  async getJudgesForAppId(appId: string): Promise<AxiosResponse<Judge[]>> {
    return this.api.get<Judge[]>(`${this.baseURL()}/${appId}/judges`)
  }

  /**
   * Gets file upload URL 
   * 
   * @returns {Promise<FileUploadResponse>}
   * @memberof AdminAPI
   */
    async postFileUploadURL(appId: string, payload: CreateFileUploadPayload): Promise<AxiosResponse<FileUploadResponse>> {
      return this.api.post<FileUploadResponse>(`${this.baseURL()}/${appId}/generate-upload-link`, payload)
    }
  


  /**
   * Create judge
   *
   * @returns {Promise<Judge>}
   * @memberof AdminAPI
   */
  async createJudgeForAppId(appId: string, payload: JudgePayload): Promise<AxiosResponse<Judge>> {
    return this.api.post<Judge>(`${this.baseURL()}/${appId}/judges`, payload)
  }

  /**
   * Update judge
   *
   * @returns {Promise<Judge>}
   * @memberof AdminAPI
   */
  async updateJudgeForAppId(appId: string, judgeId: number, payload: JudgePayload): Promise<AxiosResponse<Judge>> {
    return this.api.put<Judge>(`${this.baseURL()}/${appId}/judges/${judgeId}`, payload)
  }

  /**
   * Create mentor
   *
   * @returns {Promise<Mentor>}
   * @memberof AdminAPI
   */
   async createMentorForAppId(appId: string, payload: MentorPayload): Promise<AxiosResponse<Mentor>> {
    return this.api.post<Mentor>(`${this.baseURL()}/${appId}/mentors`, payload)
  }

  /**
   * Update mentor
   *
   * @returns {Promise<Mentor>}
   * @memberof AdminAPI
   */
  async updateMentorForAppId(appId: string, mentorId: number, payload: MentorPayload): Promise<AxiosResponse<Mentor>> {
    return this.api.put<Mentor>(`${this.baseURL()}/${appId}/mentors/${mentorId}`, payload)
  }
  
  /**
   * Gets rounds for appId
   *
   * @returns {Promise<Round[]>}
   * @memberof AdminAPI
   */
   async getRoundsForAppId(appId: string): Promise<AxiosResponse<Round[]>> {
    return this.api.get<Round[]>(`${this.baseURL()}/${appId}/rounds`)
  }

  /**
   * Create round
   *
   * @returns {Promise<Round>}
   * @memberof AdminAPI
   */
     async createRoundForAppId(appId: string, payload: RoundPayload): Promise<AxiosResponse<Round>> {
      return this.api.post<Round>(`${this.baseURL()}/${appId}/rounds`, payload)
    }
  
    /**
     * Update round
     *
     * @returns {Promise<Round>}
     * @memberof AdminAPI
     */
    async updateRoundForAppId(appId: string, roundId: number, payload: RoundPayload): Promise<AxiosResponse<Round>> {
      return this.api.put<Round>(`${this.baseURL()}/${appId}/rounds/${roundId}`, payload)
    }

    /**
     * Search for App Users
     *
     * @returns {Promise<SearchUserResponse>}
     * @memberof AdminAPI
     */
    async searchForUsers(appId: string, query: string): Promise<AxiosResponse<SearchUserResponse>> {
      return this.api.get<SearchUserResponse>(`${this.baseURL()}/${appId}/users/search`, {
        params: {
          searchTerm: query,
          limit: 100,
          skip: 0
        }
      })
    }


   /**
   * Create voting window
   *
   * @returns {Promise<VotingWindow>}
   * @memberof AdminAPI
   */
    async createWindowForAppId(appId: string, payload: VotingWindowPayload): Promise<AxiosResponse<VotingWindow>> {
      return this.api.post<VotingWindow>(`${this.baseURL()}/${appId}/votingwindow`, payload)
    }

    /**
     * Update round
     *
     * @returns {Promise<VotingWindow>}
     * @memberof AdminAPI
     */
    async updateWindowForAppId(appId: string, windowId: number, payload: VotingWindowPayload): Promise<AxiosResponse<VotingWindow>> {
     return this.api.put<VotingWindow>(`${this.baseURL()}/${appId}/votingwindow/${windowId}`, payload)
    }
  
    /**
     * Set Round Result
     *
     * @returns {Promise<Round>}
     * @memberof AdminAPI
     */
    async setRoundResultForRound(appId: string, roundId: number, payload: RoundResultPayload): Promise<AxiosResponse<RoundResult>> {
      return this.api.post<RoundResult>(`${this.baseURL()}/${appId}/rounds/${roundId}/result`, payload)
    }

    /**
     * Get Results for Round
     *
     * @returns {Promise<Round>}
     * @memberof AdminAPI
     */
    async getVoteResultForRound(appId: string, roundId: number): Promise<AxiosResponse<VoteResults>> {
      return this.api.get<VoteResults>(`${this.baseURL()}/${appId}/rounds/${roundId}/votes`)
    }

     /**
     * Add phone votes for contestant
     *
     * @returns {Promise<AxiosResponse>}
     * @memberof AdminAPI
     */
    async addPhoneVotesToWindow(appId: string, contestantId: number, windowId: number, votes: number): Promise<AxiosResponse> {
      return this.api.post(`${this.baseURL()}/${appId}/contestant/${contestantId}/phone_vote`, {
        votingWindow: windowId,
        value: votes
      })
    }


     /**
     * Send notification to all users
     *
     * @returns {Promise<AxiosResponse>}
     * @memberof AdminAPI
     */
    async sendNotificationToAllUsers(appId: string, message: string): Promise<AxiosResponse> {
      return this.api.post(`${this.baseURL()}/${appId}/push/send`, {
        message: message
       })
    }
}