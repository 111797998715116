import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AttributeEditCreateDetailsComponent = _resolveComponent("AttributeEditCreateDetailsComponent")!

  return (_openBlock(), _createBlock(_component_AttributeEditCreateDetailsComponent, {
    title: _ctx.titleText,
    subtitle: _ctx.subtitleText,
    loading: _ctx.loading,
    error: _ctx.error,
    onSaveConfirmed: _ctx.savePressed,
    onErrorShown: _cache[0] || (_cache[0] = ($event: any) => (_ctx.error = false)),
    onEditModeChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:drawerDismissable', !$event))),
    onDismiss: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('dismissSlideOver'))),
    createMode: _ctx.mode === 'CREATE',
    attributes: _ctx.getAttributesForJudge()
  }, null, 8, ["title", "subtitle", "loading", "error", "onSaveConfirmed", "createMode", "attributes"]))
}