
import { Options, Vue } from "vue-class-component";
import { Prop, Watch } from 'vue-property-decorator'
import Button from '@/components/Button.vue'
import { PencilAltIcon, SaveIcon, XIcon, BanIcon, UploadIcon, PlusIcon } from '@heroicons/vue/solid'
import { AttributeMultiSelectOption, AttributeState } from '@/types/attributes'
import contenteditable from 'vue-contenteditable'
import FileUploader from '@/components/FileUploader.vue'
import Loader from '@/components/Loader.vue'
import Modal from '@/components/Modal.vue'
// @ts-ignore
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { AttributeModalMode } from "@/types/types";
import moment from "moment";

@Options({
  name: "AttributeEditCreateDetailsComponent",
  components: {
    Button,
    PencilAltIcon,
    SaveIcon,
    XIcon,
    BanIcon,
    UploadIcon,
    contenteditable,
    flatPickr,
    FileUploader,
    Loader,
    Modal,
    PlusIcon
  },
  emits: [
    'saveConfirmed', 'editModeChange', 'errorShown', 'dismiss'
  ]
})
export default class AttributeEditCreateDetailsComponent extends Vue {   
  @Prop({type: String, require: true}) title!: string
  @Prop({type: String, require: false}) subtitle?: string
  @Prop({type: Boolean, default: false}) loading: boolean = false
  @Prop({type: Boolean, default: false}) createMode: boolean = false
  @Prop({type: Boolean, default: false}) viewOnly: boolean = false

  @Prop({type: Boolean, default: false}) error: boolean = false

  private editMode: boolean = false

  @Prop({require: true}) attributes!: AttributeState[]
  
  private uploading = false
  private showModal = false
  private modalType: AttributeModalMode = AttributeModalMode.SAVE

  private outputValues: {[key: string]: any} = {}
  private appId: string = ""

  created() {
    this.editMode = this.createMode
    this.$emit('editModeChange', this.editMode)
    this.appId = this.$route.params.appId as string
    this.attributes.forEach(attr => {
        this.outputValues[attr.key] = attr.value
    });
    
  }

  editPressed() {
    this.editMode = true
    this.$emit('editModeChange', this.editMode)
  }

  turnOffEditMode() {
    this.editMode = false
    this.$emit('editModeChange', this.editMode)
  }

  cancelPressed() {

    const valueChanges = this.calculateChanges()

    if (Object.keys(valueChanges).length === 0) {
      if (this.createMode) {
          this.$emit('dismiss')
      } else {
        this.turnOffEditMode()
      }
    } else {
      this.showModalForType(AttributeModalMode.CANCEL)
    }
  }

  @Watch('error')
  errorDidOccur() {
    if (this.error){
      this.$emit('errorShown')
      this.showModalForType(AttributeModalMode.ERROR)
    }
  }

  savePressed() {
    
    const validState = this.isValidStateForSave()

    if (!validState.isValid) {
      this.showModalForType(AttributeModalMode.MISSING_REQUIRED_FIELD)
      return
    }

    this.showModalForType(AttributeModalMode.SAVE)

  }

  modalConfirm() {
    // some code...
    this.showModal = false
    
    switch (this.modalType) {
      case AttributeModalMode.CANCEL:
        if (this.createMode) {
          //Create only so close
          this.$emit('dismiss')
        } else {
          //Dismiss changes
          this.attributes.forEach(attr => {
            this.outputValues[attr.key] = attr.value
          });
          this.turnOffEditMode()
        } 
        return
      case AttributeModalMode.SAVE:
      case AttributeModalMode.ERROR:
        //Retry on error
        this.$emit('saveConfirmed', this.calculateChanges())
        return 
      default:
          return
    }


  }

  modalCancel(close: any) {
    close()
  }

  formatDate(date: string, format: string): string {
    // locale can also be used
      return moment(date).format(format);
    }

    parseDate(datestr: string, format: string): Date {
      return moment(datestr, format, true).toDate();
    }

  isValidStateForSave(): {isValid: Boolean, attributeTitle?: string} {
    let isValid = true
    let attributeTitle: string | undefined = undefined
    
    for (let attr of this.attributes) {
        if (attr.required) {
          if (!this.outputValues[attr.key]) {
            console.log(`Attribute ${attr.title} not valid: ${this.outputValues[attr.key]}`)
            isValid = false
            attributeTitle = attr.title
            break;
          }
        }
    }

    return {isValid, attributeTitle}

  }

  isOptionSelected(key: string, option: AttributeMultiSelectOption) {

    const currentValue = this.outputValues[key]
    return currentValue.indexOf(option.value) !== -1
  }

  calculateChanges() {
    
    const changes: ({key: string, value: any } | undefined)[] = this.attributes.map(attr => {
        
        if (attr.value !== this.outputValues[attr.key]) {
          return { key: attr.key, value: this.outputValues[attr.key] }
        } else {
          return undefined
        }
     
    });

    return changes.filter(n => n)
  }

  fileUploadComplete(key: string, fileGetURL: string) {
    this.outputValues[key] = fileGetURL
  }

  uploadCancelled(key: string) {

    const originalValue = this.attributes.find( attr => {
      return attr.key === key
    })
    
    this.outputValues[key] = originalValue?.value

  }

  showModalForType(type: AttributeModalMode) {
    this.modalType = type
    this.showModal = true
  }

  get modalDistructive() {
    switch (this.modalType) {
      case AttributeModalMode.SAVE:
        return false
      default:
        return true        
    }
  }

  get modalTitle() {
    switch (this.modalType) {
      case AttributeModalMode.CANCEL:
        return "Cancel"
      case AttributeModalMode.SAVE:
        return "Save"
      case AttributeModalMode.MISSING_REQUIRED_FIELD:
        return "Error - Missing required field"
      case AttributeModalMode.ERROR:
        return "Error"
      default:
          return ""
    }
  }

  get modalConfirmButtonTitle() {
      switch (this.modalType) {
      case AttributeModalMode.CANCEL:
        return "Ok"
      case AttributeModalMode.SAVE:
        return "Save"
      case AttributeModalMode.MISSING_REQUIRED_FIELD:
        return "Ok"
      case AttributeModalMode.ERROR:
        return "Retry"
      default:
          return ""
    }
  }

  get modalMessage() {
    switch (this.modalType) {
      case AttributeModalMode.CANCEL:
        return "Are you sure you want to cancel? All data will be discarded"
      case AttributeModalMode.SAVE:
        return "Are you sure you want to save?"
      case AttributeModalMode.MISSING_REQUIRED_FIELD:

        // eslint-disable-next-line no-case-declarations
        const validState = this.isValidStateForSave()

        if (validState.attributeTitle) {
          return "Missing value for " + validState.attributeTitle
        } else {
          return "Please check you have completed all information"
        }
      case AttributeModalMode.ERROR:
        return "An error occured while saving your changes"
      default:
        return ""
    }
  }

}

