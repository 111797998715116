
import { AdminAPI } from '@/api'
import { AttributeMode, Judge, ListCellAttributes, ListCellConfiguration } from '@/types/types'
import { Options, Vue} from 'vue-class-component'
import ListComponent from '@/components/ListComponent.vue'
import { PlusIcon } from '@heroicons/vue/solid'
import SlideOverComponent from '@/components/SlideOverComponent.vue'
import JudgeEditComponent from '@/components/JudgeEditComponent.vue'
import Button from '@/components/Button.vue'

@Options({
  components: {
    ListComponent,
    PlusIcon,
    Button,
    SlideOverComponent,
    JudgeEditComponent
  }
})
export default class AppJudges extends Vue {
  private loading: Boolean = false
  private error: Boolean = false

  private listConfig: ListCellConfiguration[] = []
  private openDrawer = false
  private drawerDismissable = true
  private attributeMode: AttributeMode = AttributeMode.CREATE
  private judgeToView: Judge | null = null

  mounted() {
    this.loadData()
  }

  async loadData() {
    const appId = this.$route.params.appId as string
    try {
      this.loading = true
      const judges = (await AdminAPI.getJudgesForAppId(appId)).data
      this.mapToListConfig(judges)
      this.loading = false
    } catch (err) {
      this.loading = false
      this.error = true
    }

  }

  mapToListConfig(judges: Judge[]) {

    this.listConfig = judges.map( judge => {

      const attrs: ListCellAttributes[] = []

      attrs.push({title: "Description", value: judge.description})
      attrs.push({title: "Description Irish", value: judge.descriptionIrish})

      attrs.push({title: "Twitter", value: judge.twitterHandle ?? "N/A"})

      return {
        title: judge.name,
        imageURL: judge.profilePhoto,
        attributes: attrs,
        item: judge
      }
    })

  }

  goToCreateJudge() {
    this.judgeToView = null
    this.attributeMode = AttributeMode.CREATE
    this.openDrawer = true
  }

  itemPressed(payload: any) {
    const judge = payload.config.item as Judge
    this.attributeMode = AttributeMode.VIEW_EDIT
    this.judgeToView = judge
    this.openDrawer = true
  }

  didCompleteDetailsComponent() {
    this.openDrawer = false
    this.judgeToView = null
    this.loadData()
  }

}
