import BaseAPI from '@/api/sub/base-api'
import { 
  User,
} from '@/types/types'

import { 
  LoginPayload,
} from '@/types/payloads'
import { AxiosResponse } from 'axios'
// import { ErrorCode, BillingBasis } from '@/enums';

export default class UserAPI extends BaseAPI {

  
  /**
   * Logs in admin user
   *
   * @returns {Promise<User>}
   * @memberof UserAPI
   */
  async login(email: string, password: string): Promise<AxiosResponse<User>> {

    return this.api.post<User, AxiosResponse<User>, LoginPayload>(`${this.baseURL()}/login`, {
      email: email,
      password: password
    })
  }

    /**
   * Logs out user
   *
   * @returns {Promise<unknown>}
   * @memberof UserAPI
   */
     async logout(): Promise<AxiosResponse<unknown>> {
      return this.api.post(`${this.baseURL()}/logout`)
    }

  /**
   * Gets current user with Apps
   *
   * @returns {Promise<User>}
   * @memberof UserAPI
   */
   async getUser(): Promise<AxiosResponse<User>> {
    return this.api.get<User>(`${this.baseURL()}/user`)
  }

  /**
   * Resets Password for app user
   *
   * @returns {Promise<User>}
   * @memberof UserAPI
   */
   async resetAppUserPassword(token: string, password: string): Promise<AxiosResponse<unknown>> {
    return this.api.post<unknown>(`${this.baseURL()}/resetpassword`, {
      token: token,
      password: password
    })
  }

}