import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import ResetPassword from '../views/ResetPassword.vue'
import AppBase from '../views/app/AppBase.vue'
import AppCreate from '../views/app/AppCreate.vue'
import AppSelect from '../views/app/AppSelect.vue'
import Privacy from '../views/Privacy.vue'
import PrivacyIrish from '../views/PrivacyIrish.vue'


import Routes, { AppRoutes, AppTabRoutes, StandardRoutes } from './routes'
import AppChildRoutes from './routes/tabs'
import store from '@/store';
import { App, User } from '@/types/types'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: StandardRoutes.Home,
    component: Home
  },
  {
    path: '/login',
    name: StandardRoutes.Login,
    component: Login,
    meta: {
      loginRequired: false,
    },
  },
  {
    path: '/resetpassword/:token',
    name: StandardRoutes.ResetPassword,
    component: ResetPassword,
    meta: {
      loginRequired: false,
    },
  },
  {
    path: '/privacypolicy',
    name: StandardRoutes.PrivacyPolicy,
    component: Privacy,
    meta: {
      loginRequired: false,
    },
  },
  {
    path: '/priobhaideachas',
    name: StandardRoutes.PrivacyPolicyIrish,
    component: PrivacyIrish,
    meta: {
      loginRequired: false,
    },
  },
  {
    path: '/app',
    name: AppRoutes.AppSelect,
    component: AppSelect,
    meta: {
      loginRequired: true,
    },
  },
  {
    path: '/app/create',
    name: AppRoutes.AppCreate,
    component: AppCreate,
    meta: {
      loginRequired: true,
    },
  },
  {
    path: '/app/:appId',
    name: AppRoutes.AppBase,
    redirect: to => ({
      name: AppTabRoutes.Dashboard,
      params: { appId: to.params.appId },
    }),
    component: AppBase,
    meta: {
      loginRequired: true,
    },
    children: AppChildRoutes
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach( async (to, from, next) => {

  // Vue.prototype.$Progress.start();

  let loggedInUser: User | null = store.state.user.user
  const loginRequired = to.matched.some(record => record.meta.loginRequired)
  const noAccessWhenLoggedIn = to.matched.some(record => record.meta.noAccessWhenLoggedin)

  // Vue.prototype.$log.info('ROUTER', {
  //   to: to.path,
  //   loginRequired: loginRequired,
  //   isAdminOnlyRoute: isAdminOnlyRoute,
  //   noAccessWhenLoggedIn: noAccessWhenLoggedIn,
  //   userLoggedIn: userLoggedIn,
  //   userIsAdmin: userIsAdmin,
  //   user: user,
  //   accessToken: accessToken,
  // })


  //If we need to be logged in and we dont have a user in the store try get current user using cookie
  if (loginRequired && !loggedInUser) {
    console.log("User should be logged in but nothing in store")
    try {
      const user = await store.dispatch('user/fetchCurrentUser')
      loggedInUser = user
    } catch (err) {
      //Couldn't get user, log them out and go to login
      console.log("Failed to load user - Move to login")
      next({
        name: Routes.Standard.Login,
        query: { redirect: to.fullPath },
      })
      return
    }
  }

  const appIdToAccess = to.params.appId

  //If we are trying to get at an appId as param check we should have access to it
  if (appIdToAccess && loggedInUser) {
    
    const validApp = loggedInUser.apps?.find( app => app.appId === appIdToAccess )

    if (!validApp) {
      console.log("User tried to access invalid app / App id no access to")
      
      //Since we are logged in lets redirect back to app select

      next({
        name: Routes.AppRoutes.AppSelect,
      })
      return
    }

  } else if (appIdToAccess && !loggedInUser && loginRequired) {
    //Somehow trying to get at an appId without being logged in
    console.log("User not logged in and tried to access appId URL")
    next({
      name: Routes.Standard.Login,
      query: { redirect: to.fullPath },
    })
    return
  }


  // All checks complete, continue to route
  // Vue.prototype.$log.info('ROUTER', 'Route check complete', to)
  next()
  return

  // // If the user has logged in but there is no user data in the store
  // if ((userLoggedIn || accessToken) && !user) {
  //   Vue.prototype.$log.info('ROUTER', 'User is logged in but there is no user data in the store, fetching the current user')
  //   await store.dispatch('fetchCurrentUser')
  //   .then(async(newUser: User) => {
  //     user = newUser
  //     userIsAdmin = user.staffRole === StaffRole.SUPERADMIN
  //     userLoggedIn = true
  //   })
  //   .catch((error) => {
  //     userLoggedIn = false
  //     user = null
  //     Vue.prototype.$log.error(error)
  //   })
  // }

  // // Login required, but the user is not logged in
  // if (loginRequired && (!userLoggedIn && !user)) {
  //   Vue.prototype.$log.info('ROUTER', 'User trying to access logged in only route while not logged in', userLoggedIn, user)
  //   next({
  //     name: Routes.Standard.Login,
  //     query: { redirect: to.fullPath },
  //   })
  //   return
  // }

  // // User is logged in but is trying to access a route which cannot be access while logged in
  // if (noAccessWhenLoggedIn && userLoggedIn) {
  //   Vue.prototype.$log.info('ROUTER', 'Logged in user trying to access route which can only be accessed while logged out', to)
  //   next({ path: '/app' })
  //   return
  // }

  // // No admin user trying to access an admin only route
  // if (isAdminOnlyRoute && !userIsAdmin) {
  //   Vue.prototype.$log.info('ROUTER', 'Non admin user trying to access an admin only route', to)
  //   next({ path: '/app' })
  //   return
  // }

  // // All checks complete, continue to route
  // Vue.prototype.$log.info('ROUTER', 'Route check complete', to)
  // next()
  // return

});


router.afterEach((to) => {
  // const spinner = document.getElementById('fullscreen-spinner-overlay');
  // if (spinner) {
  //   spinner.classList.add('hidden');
  //   setTimeout(() => {
  //     if (spinner.parentNode){
  //       spinner.parentNode.removeChild(spinner);
  //     }
  //   }, 1000);
  // }
  let name: string = ''
  if (to.path) { name = to.path; }
  const toName = <string>to.name
  if (toName) { name = toName; }
  // if (Vue.prototype.$analytics) {
  //   Vue.prototype.$analytics.trackPageView(name);
  // }
  Routes.updateCurrentRoute(name);
  // Vue.prototype.$Progress.finish();
});

export default router
