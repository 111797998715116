
import { DropDownOption } from '@/types/types'
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/solid'
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

@Options( {
  components: {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    ChevronDownIcon,
    },
    emits: [
      'didSelectOption'
  ]
})
export default class RoundDropdownMenu extends Vue {

  @Prop({}) options: DropDownOption[] = [] 
  @Prop({}) initialOption: DropDownOption | null = null

  private selectedOption: DropDownOption | null = null


  @Watch('initialOption')
  updateParams() {
    this.selectedOption = this.initialOption
  }

  created() {
    this.selectedOption = this.initialOption
  }

  getCurrentOptionName() {
    return this.selectedOption?.title ?? "Select Round"
  }

  @Watch('selectedOption')
  emitSelection() {
    if (this.selectedOption) {
      this.$emit('didSelectOption', this.selectedOption.object)
    }
  }
}
