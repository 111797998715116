import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-627bbece"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list-cell-main-wrapper hover:bg-gray-50 cursor-pointer" }
const _hoisted_2 = { class: "list-cell-horizontal-chevron-wrapper" }
const _hoisted_3 = { class: "list-cell-vertical-content-wrapper" }
const _hoisted_4 = { class: "list-cell-top-header" }
const _hoisted_5 = { class: "list-cell-title-subtitle-wrapper" }
const _hoisted_6 = { class: "list-cell-title font-medium text-left text-2xl text-b" }
const _hoisted_7 = {
  key: 0,
  class: "list-cell-subtitle font-regular text-left text-1xl text-indigo-600"
}
const _hoisted_8 = { class: "list-cell-attribute-list-wrapper" }
const _hoisted_9 = { class: "list-cell-attribute-title font-medium text-left text-1xl text-indigo-600" }
const _hoisted_10 = { class: "list-cell-attribute-value font-regular text-left text-1xl text-black" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronRightIcon = _resolveComponent("ChevronRightIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.configuration.imageURL)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["list-cell-image shadow", {'square': _ctx.squareImage}]),
                style: _normalizeStyle(_ctx.applyImageStyle())
              }, null, 6))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.configuration.title), 1),
            (_ctx.configuration.subtitle)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.configuration.subtitle), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.configuration.attributes, (attr) => {
            return (_openBlock(), _createElementBlock("div", {
              key: attr.title,
              class: "list-cell-attibute-wrapper"
            }, [
              _createElementVNode("div", _hoisted_9, _toDisplayString(attr.title), 1),
              _createElementVNode("div", _hoisted_10, _toDisplayString(attr.value), 1)
            ]))
          }), 128))
        ]),
        _renderSlot(_ctx.$slots, "default", {
          item: _ctx.configuration.item
        }, undefined, true)
      ]),
      _createVNode(_component_ChevronRightIcon, {
        class: "h-5 w-5 ml-4 flex-shrink-0 text-gray-400",
        "aria-hidden": "true"
      })
    ])
  ]))
}