
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import AttributeEditCreateDetailsComponent from "@/components/AttributeEditCreateDetailsComponent.vue";
import {
  AttributeMode,
  Contestant,
  Judge,
  Round,
  VotingWindow,
} from "@/types/types";
import {
  AttributeMultiSelectOption,
  AttributeState,
  AttributeType,
} from "@/types/attributes";
import { RoundPayload } from "@/types/payloads";
import { AdminAPI } from "@/api";
import AddUpdateVotingWindowModal from "./AddUpdateVotingWindowModal.vue";
import { CalendarIcon, PlusIcon } from "@heroicons/vue/outline";
import moment from "moment";

@Options({
  name: "RoundEditComponent",
  components: {
    AttributeEditCreateDetailsComponent,
    AddUpdateVotingWindowModal,
    PlusIcon,
    CalendarIcon
  },
  emits: [
    "detailsComponentDidComplete",
    "update:drawerDismissable",
    "dismissSlideOver",
  ],
})
export default class RoundEditComponent extends Vue {
  @Prop() roundToView: Round | null = null;
  @Prop({ require: true }) mode: AttributeMode = AttributeMode.CREATE;
  @Prop({ type: Boolean, default: true }) drawerDismissable: Boolean = true;

  private votingWindowToEdit: VotingWindow | null = null

  private loading: boolean = false;
  private error: boolean = false;
  private showVotingWindowsModal: boolean = false;
  private editMode = false;
  private votingWindows: VotingWindow[] = [];

  get titleText() {
    if (this.roundToView) {
      return this.roundToView.name;
    } else {
      return "Create Round";
    }
  }

  get subtitleText() {
    if (this.roundToView) {
      return "View and edit";
    } else {
      return null;
    }
  }

  private contestants: Contestant[] = [];
  private judges: Judge[] = [];
  async mounted() {
    const appId = this.$route.params.appId as string;
    this.votingWindows = this.roundToView?.votingWindows ?? [];
    this.contestants = (await AdminAPI.getContestantsForAppId(appId)).data;
    this.judges = (await AdminAPI.getJudgesForAppId(appId)).data;
  }
  
  formatDate(date: string) {
    return moment(date).format('MMMM Do YYYY, HH:mm:ss')
  }

  editModeDidChange(event: boolean) {
    this.editMode = !event;
    this.$emit("update:drawerDismissable", event);
  }

  savePressed(changes: { key: string; value: string }[]) {

    let payload: { [key: string]: any } = {};
    changes.forEach((obj) => (payload[obj.key] = obj.value));

    if (this.votingWindows.length == 0 && payload['publicVote']) {
      alert("Please create at least one voting window");
      return
    }
    const windowIds = this.votingWindows.map( window => window.id)
    payload.votingWindows = windowIds
    switch (this.mode) {
      case AttributeMode.CREATE:
        this.createRound(payload as RoundPayload);
        return;
      case AttributeMode.VIEW_EDIT:
        this.updateRound(payload as RoundPayload);
        return;
    }
  }

  async createRound(payload: RoundPayload) {
    this.loading = true
    this.error = false
    const appId = this.$route.params.appId as string;
    try {
      await AdminAPI.createRoundForAppId(appId, payload);
      this.$emit("detailsComponentDidComplete");
    } catch (err) {
      console.error(err);
      this.error = true;
    } finally {
      this.loading = false;
    }
  }

  async updateRound(payload: RoundPayload) {
    const roundId = this.roundToView?.id;

    if (!roundId) {
      this.error = true;
      return;
    }

    this.loading = true;
    const appId = this.$route.params.appId as string;
    try {
      await AdminAPI.updateRoundForAppId(appId, roundId, payload);
      this.$emit("detailsComponentDidComplete");
    } catch (err) {
      console.error(err);
      this.error = true;
    } finally {
      this.loading = false;
    }
  }

  getAttributesForRound(): AttributeState[] {
    const contestantOptions: AttributeMultiSelectOption[] =
      this.contestants.map((contestant) => {
        return {
          value: contestant.id,
          title: contestant.name,
          imageUrl: contestant.profilePhoto,
        };
      });

    const currentContestantIds =
      this.roundToView?.contestants.map((contestant) => contestant.id) ?? [];

    const judgeOptions: AttributeMultiSelectOption[] = this.judges.map(
      (contestant) => {
        return {
          value: contestant.id,
          title: contestant.name,
          imageUrl: contestant.profilePhoto,
        };
      }
    );

    const currentJudgeIds = this.roundToView?.judges.map((judge) => judge.id) ?? [];

    return [
      {
        type: AttributeType.TEXT,
        required: true,
        title: "Name",
        value: this.roundToView?.name,
        placeholder: "Round Name",
        key: "name",
      },
      {
        type: AttributeType.TEXT,
        required: true,
        title: "Name Irish",
        value: this.roundToView?.name_irish,
        placeholder: "Round Name in irish",
        key: "name_irish",
      },
      {
        type: AttributeType.TEXT,
        required: false,
        title: "Subtitle",
        value: this.roundToView?.subtitle,
        placeholder: "Round subtitle",
        key: "subtitle",
      },
      {
        type: AttributeType.TEXT,
        required: false,
        title: "Subtitle Irish",
        value: this.roundToView?.subtitle_irish,
        placeholder: "Round subtitle in Irish",
        key: "subtitle_irish",
      },
      {
        type: AttributeType.BOOLEAN,
        required: false,
        title: "Public vote",
        value: this.roundToView?.publicVote,
        placeholder: "Public voting enabled",
        key: "publicVote",
      },
       {
        type: AttributeType.BOOLEAN,
        required: false,
        title: "Are contestants publicly visible yet?",
        value: this.roundToView?.isPubliclyVisible,
        placeholder: "Should the app show contestants and judges for this round yet?",
        key: "isPubliclyVisible",
      },
      {
        type: AttributeType.DATE_TIME,
        required: true,
        title: "Start Date",
        value: this.roundToView?.startDate,
        placeholder: "Round start date",
        key: "startDate",
      },
      {
        type: AttributeType.MULTI_SELECT,
        required: true,
        title: "Contestants",
        value: currentContestantIds,
        placeholder: "Select Contestants",
        key: "contestants",
        options: contestantOptions,
      },
       {
        type: AttributeType.MULTI_SELECT,
        required: true,
        title: "Contestants ineligible to vote",
        value: this.roundToView?.contestantsIneligibleForVote ?? [],
        placeholder: "Select Contestants to not include in vote (safe)",
        key: "contestantsIneligibleForVote",
        options: contestantOptions,
      },
      {
        type: AttributeType.MULTI_SELECT,
        required: true,
        title: "Judges",
        value: currentJudgeIds,
        placeholder: "Select Judges",
        key: "judges",
        options: judgeOptions,
      },
    ];
  }

  didSelectWindowToEdit(window: VotingWindow) {
    this.votingWindowToEdit = window
    this.showVotingWindowsModal = true
  }

  showAppUpdateModal() {
    this.votingWindowToEdit = null
    this.showVotingWindowsModal = true
  }

  modalCancel(close: any) {
    close();
  }

  didUpdateCreateVotingWindow(window: VotingWindow, close: any) {
    
    close();
    const originalWindowIndex = this.votingWindows.findIndex( oldWindow => {
      return oldWindow.id === window.id
    })
    if (originalWindowIndex == -1) {
      this.votingWindows.push(window)
    } else {
      this.votingWindows[originalWindowIndex] = window
    }
    
  }
}
