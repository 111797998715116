
import { ListCellConfiguration } from '@/types/types';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator'
import Loader from '@/components/Loader.vue'
import ListCell from '@/components/ListCell.vue'
import { RefreshIcon } from '@heroicons/vue/solid'

@Options({
  components: {
    Loader,
    ListCell,
    RefreshIcon
  }
})
export default class ListComponent extends Vue {

  @Prop({ type: Boolean, default: false }) readonly squareImage!: Boolean

  @Prop({ type: Boolean, default: false }) readonly loading!: Boolean
  @Prop({ type: Boolean, default: false }) readonly error!: Boolean

  @Prop({ type: String, default: "Create new" }) readonly emptyStateText!: String
  
  @Prop() readonly listConfig?: ListCellConfiguration[]

  @Prop({ required: false }) emptyStatePressed?: Function;
  @Prop({ required: true }) retryPressed!: Function;


  didSelect(config: ListCellConfiguration, index: number) {
    
    this.$emit('did-select-list-item', { config: config, index: index})
  }


}
