
import { AdminAPI } from '@/api'
import { AttributeMode, ListCellAttributes, ListCellConfiguration, Round } from '@/types/types'
import { Options, Vue} from 'vue-class-component'
import ListComponent from '@/components/ListComponent.vue'
import { PlusIcon } from '@heroicons/vue/solid'
import SlideOverComponent from '@/components/SlideOverComponent.vue'
import RoundEditComponent from '@/components/RoundEditComponent.vue'
import Button from '@/components/Button.vue'
import moment from 'moment'
import { CakeIcon } from '@heroicons/vue/outline'
import Routes, { AppTabRoutes } from '@/router/routes'

@Options({
  components: {
    ListComponent,
    PlusIcon,
    Button,
    SlideOverComponent,
    RoundEditComponent,
    CakeIcon
  }
})
export default class AppRounds extends Vue {

  private loading: Boolean = false
  private error: Boolean = false

  private listConfig: ListCellConfiguration[] = []
  private openDrawer = false
  private drawerDismissable = true
  private attributeMode: AttributeMode = AttributeMode.CREATE
  private roundToView: Round| null = null

  mounted() {
    this.loadData()
  }

  async loadData() {
    const appId = this.$route.params.appId as string
    try {
      this.loading = true
      const contestants = (await AdminAPI.getRoundsForAppId(appId)).data
      this.mapToListConfig(contestants)
      this.loading = false
    } catch (err) {
      this.loading = false
      this.error = true
    }
  }

  mapToListConfig(rounds: Round[]) {

    this.listConfig = rounds.map( round => {

    const attrs: ListCellAttributes[] = []

      attrs.push({title: "Public voting:", value: round.publicVote ? 'Yes' : 'No'})
      attrs.push({title: "Are contestants and judges visible to public?", value: round.isPubliclyVisible ? 'Yes': 'No'})
        
    const date = moment(round.startDate).format('MMMM Do YYYY, HH:mm:ss')
      
      return {
        title: round.name,
        subtitle: `Starts: ${date}`,
        imageURL: undefined,
        attributes: attrs,
        item: round
      }
    })

  }

  goToCreateRound() {
    this.roundToView = null
    this.attributeMode = AttributeMode.CREATE
    this.openDrawer = true
  }

  itemPressed(payload: any) {
    const roundToView = payload.config.item as Round
    this.attributeMode = AttributeMode.VIEW_EDIT
    this.roundToView = roundToView
    this.openDrawer = true
  }

  didCompleteDetailsComponent() {
    this.openDrawer = false
    this.roundToView = null
    this.loadData()
  }

  setRoundResultForRound(item: Round) {
    const appId = this.$route.params.appId as string
    Routes.go(AppTabRoutes.RoundResults, {
        params: {
          appId: appId,
          roundId: item.id}
    })
    
  }

  getWinningContestants(item: Round) {
    return item.contestants.filter( (contestant) => {
      return item.result?.winningContestants.indexOf(contestant.id) !== -1
    } )

  }

  getEliminatedContestants(item: Round) {
    return item.contestants.filter( (contestant) => {
      return item.result?.eliminatedContestants.indexOf(contestant.id) !== -1
    } )
  }


  getContestantsThroughToNextRound(item: Round) {
    return item.contestants.filter( (contestant) => {
      return item.result?.eliminatedContestants.indexOf(contestant.id) === -1 && item.result?.winningContestants.indexOf(contestant.id) === -1
    } )
  }

}
