
import { AdminAPI } from '@/api'
import Loader from '@/components/Loader.vue'
import Routes, { AppRoutes } from '@/router/routes'
import { PlusIcon } from '@heroicons/vue/solid'
import { Options, Vue} from 'vue-class-component'

@Options({
  name: 'AppCreate',
  components: {
    PlusIcon,
    Loader
  }
  })
export default class AppCreate extends Vue {
  
  appName: string = ""
  loading: Boolean = false

  async createAppPressed() {
    this.loading = true
    try {
      const app = (await AdminAPI.createApp(this.appName)).data
      await this.$store.dispatch('user/fetchCurrentUser')
      Routes.go(AppRoutes.AppBase, {
        params: {appId: app.appId}
      })
    } catch ( error) {
      alert("Failed to create new season");
    } finally {
      this.loading = false
    }

  }
}

