
import Routes, { AppRoutes } from '@/router/routes'
import { App, AppSelection } from '@/types/types'
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/solid'
import moment from 'moment'
import { Options, Vue } from 'vue-class-component'

@Options( {
  components: {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    ChevronDownIcon,
    }
})
export default class AppDropdownMenu extends Vue {

  private options: AppSelection[] = []
  private selectedApp: AppSelection | null = null

  mounted() {
    const userApps = this.$store.state.user.user?.apps ?? []
    const appId = this.$route.params.appId as string
    let initialIndex: number = -1

    this.options = userApps.map( (app, index) => {

      if (app.appId === appId) {
        initialIndex = index
      }

        return { 
          title: app.name,
          subtitle: this.formattedDateForApp(app),
          app: app,
          key: ""+app.id
        }
    })

    this.options.push( {
      title: 'Create new season',
      key: "create_app_key"
    })

    if (initialIndex !== -1){
      this.selectedApp = this.options[initialIndex]
    }

  }

  getCurrentAppName() {
    return this.selectedApp?.title ?? "Select App"
  }

  didSelectOption(index: number) {
    const option = this.options[index]
    if (option.app) {
      this.didSelectApp(option.app)
    } else {
      this.createAppPressed()
    }
  }

  createAppPressed() {
    Routes.go(AppRoutes.AppCreate)
  }

  didSelectApp(app: App) { 
    Routes.go(AppRoutes.AppBase, {
      params: {appId: app.appId}
    })
  }

  formattedDateForApp(app: App) {
    return moment(app.createdAt).format('MM/DD/YYYY')
  }

}
