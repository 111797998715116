export interface User {
  id: number,
  appId?: string,
  defaultApp?: string
  apps?: App[]
}

export interface App {
  id: number,
  appId: string,
  name: string
  createdAt: string
}

export interface ListCellConfiguration {
  title: string,
  subtitle?: string,
  imageURL?: string,
  attributes: ListCellAttributes[],
  item: any
}

export interface ListCellAttributes {
  title: string,
  value: string
}


export interface Contestant {
  id: number,
  name: string,
  firstName: string,
  lastName: string,
  descriptionIrish: string,
  description: string,
  profilePhoto: string,
  location: string,
  locationIrish: string,
  twitterHandle?: string,
  twitterText?: string,
  twitterTextIrish?: string,
  eliminated: boolean,
  createdAt: string
}


export interface Mentor {
  id: number,
  name: string,
  firstName: string,
  lastName: string,
  descriptionIrish: string,
  description: string,
  profilePhoto: string,
  twitterHandle?: string,
  contestants: Contestant[]
}


export interface Judge {
  id: number,
  name: string,
  firstName: string,
  lastName: string,
  descriptionIrish: string,
  description: string,
  profilePhoto: string,
  twitterHandle?: string,
}

export interface AppSelection {
  title: string,
  subtitle?: string,
  app?: App,
  key: string 
}

export interface FileUploadResponse {
  url: string
}

export interface Round {
  id: number,
  name: string,
  name_irish: string,
  subtitle?: string,
  subtitle_irish?: string,
  startDate: string,
  publicVote: Boolean,
  isPubliclyVisible: Boolean,
  result?: RoundResult,
  contestants: Contestant[],
  judges: Judge[],
  votingWindows: VotingWindow[]
  contestantsIneligibleForVote: number[]
}

export interface VotingWindow{
  id: number,
  openingTime: string,
  closingTime: string,
  active: boolean
}

export interface RoundResult {
  id: number,
  type: string,
  contestants: Contestant[]
  eliminatedContestants: number[],
  winningContestants: number[]
}

export interface SearchUserResponse {
  skip: number,
  limit: number,
  count: number,
  users: User[]
}

export interface User {
  id: number,
  email: string,
  name: string,
  fbId: string,
  appleId: string,
  age?: string,
  sex?: string,
  location?: string,
  remainingVotes: number,
  deletedAccount?: boolean,
  purchases: Purchase[]
  votes: Vote[]
}

export interface Purchase {
  id: number,
  votes: number,
  price: number,
  transactionId: string,
  localeId: string,
  createdAt: string
}

export interface Vote {
  contestantName: string,
  type: string
}

export enum AttributeMode {
  CREATE = "CREATE",
  VIEW_EDIT = "VIEW_EDIT"
}


export enum AttributeModalMode {
  SAVE = "SAVE",
  CANCEL = "CANCEL",
  MISSING_REQUIRED_FIELD = "MISSING_REQUIRED_FIELD",
  ERROR = "ERROR"
}

export enum RoundResultsModalMode {
  CONTESTANT_CROSSOVER = "CONTESTANT_CROSSOVER",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS"
}

export interface VoteResults {
  round: Round,
  contestantResults: ContestantResult[]
}

export interface ContestantResult {
  contestant: number,
  votesPerWindow: WindowResult[]
}

export interface WindowResult {
  window: number,
  phoneVotes: number,
  appVotes: number,
  totalVotes: number
}


export interface DropDownOption {
  title: string,
  object: Round,
  key: string 
}

export interface GraphOptions {
  chart: {id: string}
  xaxis: { categories: string[]}
}

export interface GraphSeries {
  name: string
  data: number[]
}