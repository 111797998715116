
import { ref, RenderFunction } from 'vue'
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import {
  AcademicCapIcon,
  BellIcon,
  ChartBarIcon,
  MenuAlt2Icon,
  MusicNoteIcon,
  ScaleIcon,
  SpeakerphoneIcon,
  UsersIcon,
  XIcon,
  UserCircleIcon,
  TableIcon
} from '@heroicons/vue/outline'
import { SearchIcon } from '@heroicons/vue/solid'
import Routes, { AppTabRoutes, StandardRoutes } from '@/router/routes'
import { Options, Vue } from 'vue-class-component'
import { UserAPI } from '@/api'
import AppDropdownMenu from '@/components/AppDropdownMenu.vue'

@Options( {
  components: {
      Dialog,
      DialogOverlay,
      Menu,
      MenuButton,
      MenuItem,
      MenuItems,
      TransitionChild,
      TransitionRoot,
      BellIcon,
      MenuAlt2Icon,
      SearchIcon,
      XIcon,
      AppDropdownMenu,
      UserCircleIcon,
      TableIcon
    }
})
export default class AppBase extends Vue {
  
  signOutPressed = async () => {
    console.log("Sign out pressed")
    try {
      await UserAPI.logout()
      Routes.go(StandardRoutes.Home)
    } catch (err) {
      console.error(err)
      // TODO: Show Errors
    }
  }

  userNavigation: { name: String, onClick: () => void}[] = [
    { name: 'Sign out', onClick: this.signOutPressed },
  ]

  navigation: { name: String, route: AppTabRoutes, icon: RenderFunction }[]= [
    { name: 'Dashboard', route: AppTabRoutes.Dashboard, icon: ChartBarIcon },
    { name: 'Rounds', route:  AppTabRoutes.Rounds, icon: TableIcon },
    { name: 'Push Notification', route:  AppTabRoutes.PushNotification, icon: SpeakerphoneIcon },
    { name: 'Users', route:  AppTabRoutes.Users, icon: UsersIcon },
    { name: 'Contestants', route:  AppTabRoutes.Contestants, icon: MusicNoteIcon },
    { name: 'Mentors', route: AppTabRoutes.Mentors, icon: AcademicCapIcon },
    { name: 'Judges', route:  AppTabRoutes.Judges, icon: ScaleIcon },
    // { name: 'Voting Windows', route:  AppTabRoutes.VotingWindows, icon: ClipboardCheckIcon },
  ]

  sidebarOpen = ref(false)

}
