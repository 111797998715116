
import { Options, Vue } from "vue-class-component";


@Options({
  name: "Loader"
})
export default class Loader extends Vue {   
}

