export enum AttributeType {
  TEXT = "TEXT",
  BOOLEAN = "BOOLEAN",
  DATE_TIME = "DATE_TIME",
  LONG_TEXT = "LONG_TEXT",
  IMAGE = "IMAGE",
  MULTI_SELECT = "MULTI_SELECT",
  SINGLE_SELECT = "SINGLE_SELECT",
  CHILD_OBJECTS = "CHILD_OBJECTS",
}


export interface AttributeState {
  type: AttributeType,
  required: boolean,
  title: string,
  value?: string | number[] | Boolean,
  placeholder: string,
  key: string,
  options?: AttributeMultiSelectOption[]
  childObjectAttributes?: ChildObjectAttributes[]
}

export interface AttributeMultiSelectOption {
  imageUrl?: string,
  value: number | string,
  title: string
}

export interface ChildObjectAttributes {
  id: number
  attributes: {title: string, value: string}[]
}