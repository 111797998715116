import { UserAPI } from '@/api';
import { User } from '@/types/types'
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '..';

// initial state
// shape: [{ id, quantity }]
export type UserState = {
  user: User | null;
}

const state: UserState = {
  user: null
}

// getters
export type Getters = {
  currentUser(state: UserState): User | null;
}

const getters: GetterTree<UserState, RootState> & Getters = {
  currentUser: (state) => state.user,
};


// actions
const actions: ActionTree<UserState, RootState> = {
  async fetchCurrentUser ({ commit }): Promise<User> {
    
    // eslint-disable-next-line
    return new Promise(async (resolve, reject) => {

      try {
        const user = (await UserAPI.getUser()).data
        commit('setCurrentUser', { user })
        resolve(user)
      } catch (err) {
        console.error(err)
        commit('clearCurrentUser')
        reject(err)
      }
    })
  },
}

// mutations
const mutations: MutationTree<UserState> = {

  setCurrentUser (state, { user }) {

    state.user = user
  },

  clearCurrentUser(state) {
    state.user = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: { }
}