
import { Options, Vue } from "vue-class-component";
import { Prop } from 'vue-property-decorator'
import AttributeEditCreateDetailsComponent from '@/components/AttributeEditCreateDetailsComponent.vue'
import { AttributeMode, Contestant } from "@/types/types";
import { AttributeState, AttributeType } from "@/types/attributes";
import { ContestantPayload } from "@/types/payloads";
import { AdminAPI } from "@/api";


@Options({
  name: "ContestantEditComponent",
  components: {
    AttributeEditCreateDetailsComponent
  },
  emits: ['detailsComponentDidComplete', 'update:drawerDismissable', 'dismissSlideOver']
})
export default class ContestantEditComponent extends Vue {   

  @Prop() contestantToView: Contestant | null = null
  @Prop({require: true}) mode: AttributeMode = AttributeMode.CREATE
  @Prop({type: Boolean, default: true }) drawerDismissable: Boolean = true
    
  private loading: boolean = false
  private error: boolean = false

  get titleText() {
    if (this.contestantToView) {
      return this.contestantToView.name
    } else {
      return "Create Contestant"
    }
  }

  get subtitleText() {
    if (this.contestantToView) {
      return "View and edit"
    } else {
      return null
    }
  }

  savePressed(changes: {key: string, value: string }[]) {
    let payload: {[key:string]: any}  = {}
    changes.forEach( obj => 
      payload[obj.key] = obj.value
    )

    switch (this.mode) {
      case AttributeMode.CREATE:
        this.createContestant(payload)
        return;
      case AttributeMode.VIEW_EDIT:
        this.updateContestant(payload)
        return;
    }
  }

  async createContestant(payload: ContestantPayload) {

    this.loading = true
    const appId = this.$route.params.appId as string
    try {
      await AdminAPI.createContestantsForAppId(appId, payload)
      this.error = false
      this.$emit('detailsComponentDidComplete')
    } catch (err) {
      console.error(err)
      this.error = true
    } finally {
      this.loading = false
    }
  }

  async updateContestant(payload: ContestantPayload) {
    const contestantId = this.contestantToView?.id

    if (!contestantId) {
      this.error = true
      return
    }
    
    this.loading = true
    const appId = this.$route.params.appId as string
    try {
      await AdminAPI.updateContestantsForAppId(appId, contestantId, payload)
      this.$emit('detailsComponentDidComplete')
    } catch (err) {
      console.error(err)
      this.error = true
    } finally {
      this.loading = false
    }

}

  getAttributesForContestant(): AttributeState[] {
    
    return [
      {type: AttributeType.IMAGE, required: true, title: "Image", value: this.contestantToView?.profilePhoto, placeholder: "Profile Photo", key: "profilePhoto"},
      {type: AttributeType.TEXT, required: true, title: "First Name", value: this.contestantToView?.firstName, placeholder: "Enter First name", key: "firstName"},
      {type: AttributeType.TEXT, required: true, title: "Last Name", value: this.contestantToView?.lastName, placeholder: "Enter Last name", key: "lastName"},
      {type: AttributeType.LONG_TEXT, required: true, title: "Description", value: this.contestantToView?.description, placeholder: "Enter Description", key: "description"},
      {type: AttributeType.LONG_TEXT, required: true, title: "Description Irish", value: this.contestantToView?.descriptionIrish, placeholder: "Enter Description in Irish", key: "descriptionIrish"},
      {type: AttributeType.TEXT, required: true, title: "Location", value: this.contestantToView?.location, placeholder: "Enter Location", key: "location"},
      {type: AttributeType.TEXT, required: true, title: "Location Irish", value: this.contestantToView?.locationIrish, placeholder: "Enter Location in Irish", key: "locationIrish"},
      {type: AttributeType.TEXT, required: false, title: "Twitter Handle", value: this.contestantToView?.twitterHandle, placeholder: "Enter Twitter Handle", key: "twitterHandle"},
      {type: AttributeType.LONG_TEXT, required: false, title: "Twitter Share Text", value: this.contestantToView?.twitterText, placeholder: "Enter Share text for Twitter", key: "twitterText"},
      {type: AttributeType.LONG_TEXT, required: false, title: "Twitter Share Text Irish", value: this.contestantToView?.twitterTextIrish, placeholder: "Enter Share text for Twitter in Irish", key: "twitterTextIrish"},
    ]
  }
  
}

