
// @ts-nocheck
import { Prop } from "vue-property-decorator"
import vueFilePond from "vue-filepond"
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type"
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size"
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';

import FilePingPluginCrop from 'filepond-plugin-image-crop';

import { Options, Vue } from "vue-class-component";
import { AdminAPI } from "@/api";
import { CreateFileUploadPayload } from "@/types/payloads";
import { v4 as uuidv4 } from 'uuid';

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginFileValidateSize, FilePondPluginImageResize, FilePondPluginImageTransform, FilePingPluginCrop)

@Options({
  name: "FileUploader",
  components: {
    FilePond
  }
})
export default class FileUploader extends Vue {

  @Prop({required: true}) attributeKey!: string
  @Prop({required: true}) uploadComplete!: Function
  @Prop({required: true}) uploadCancelled!: Function
  @Prop({required: true}) uploading!: boolean
  @Prop({required: true}) appId!: string

  filePond: any = null
  files: any[] = []
  server = {}
  fileUploadURL?: string
  fileGetURL?: string


  created() {
    this.server = {
      process: this.processFile,
      revert: () => {},
      remove: () => {},
    }
  }

  acceptedFileTypes = [
    'image/*',
  ].join(',')

  filePondReady() {
    this.filePond = this.$refs.pond
  }

  browse() {
    this.filePond.browse()
  }

  fileAbort() {
    this.uploadCancelled(this.attributeKey)
  }

  beforeAddFile(pond) {

    // eslint-disable-next-line
    return new Promise(async(resolve, _) => {
      
      let file = pond.file
      let fileExtension = file.name.substring(file.name.indexOf('.'))

      this.$emit('update:uploading', true)

      try {
        file.header = await this.setFileHeader(file)
      } catch (err) {
        // Vue.prototype.$log.error(err)
        console.error(err)
        file.error = 'There was an error processing your file'
        resolve(false)
        return
      }

      
      let filePayload: CreateFileUploadPayload = {
        name: uuidv4() + fileExtension,
        size: file.size,
        type: file.type,
        header: file.header
      };

      try {
        const uploadURL = (await AdminAPI.postFileUploadURL(this.appId, filePayload)).data.url
        this.fileUploadURL = uploadURL
        this.fileGetURL = uploadURL.split("?")[0]
      } catch (err) {
        Vue.prototype.$log.error(err)
        file.error = 'Sorry this file type is not supported'
        resolve(true) // continue as an error will be shown in processFile
        return
      }

      resolve(true)

    })
  }

  processFile(fieldName, files, metadata, load, error, progress, abort) {
    try {
      //N.B when using custom server and transforms we actually get a blob back so need to recreate the file
      const file = new File([files], "output_image");
      if (file.error) {
        error(file.error)
        return
      }
      console.log("Uploading to: ", this.fileUploadURL)
      const request = new XMLHttpRequest();
      request.open('PUT', this.fileUploadURL);

      request.setRequestHeader('Content-Type', file.type)
      
      // Should call the progress method to update the progress to 100% before calling load
      // Setting computable to false switches the loading indicator to infinite mode
      request.upload.onprogress = (e) => {
        progress(e.lengthComputable, e.loaded, e.total);
      };

      // Should call the load method when done and pass the returned server file id
      // this server file id is then used later on when reverting or restoring a file
      // so your server knows which file to return without exposing that info to the client
      const _this = this
      request.onload = function() {
        if (request.status >= 200 && request.status < 300) {
          load(request.responseText);
          _this.uploadComplete(_this.attributeKey, _this.fileGetURL)
          _this.$emit('update:uploading', false)
        }
        else {
          // Can call the error method if something is wrong, should exit after
          error('Sorry there was an error uploading your file')
        }
      };


      // Start the upload 
      request.send(file)
      
      // Should expose an abort method so the request can be cancelled
      return {
        abort: () => {
          // This function is entered if the user has tapped the cancel button
          request.abort()
          abort()
        }
      };
    } catch (err) {
      console.error(err)
      error(err)
      return
    }
  }

  async setFileHeader(file) {
    return new Promise((resolve, reject) => {
      var fileReader = new FileReader();
      fileReader.onloadend = (readFile) => {
        if (readFile && readFile.target && readFile.target.result) {
          let result: any = readFile.target.result
          var arr = (new Uint8Array(result)).subarray(0, 4);
          var header = "";
          for(var i = 0; i < arr.length; i++) {
            header += arr[i].toString(16);
          }
          resolve(header)
        }
      };
      fileReader.onerror = reject
      fileReader.readAsArrayBuffer(file.slice(0, 4))
    })
  }

}


