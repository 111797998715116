
import { Options, Vue } from "vue-class-component";
// @ts-ignore
import { VueFinalModal } from 'vue-final-modal'
import { ClipboardCheckIcon } from '@heroicons/vue/outline'

// @ts-ignore
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Loader from "./Loader.vue";
import { Prop, Watch } from "vue-property-decorator";
import { VotingWindow } from "@/types/types";
import { AdminAPI } from "@/api";
import moment from "moment";

@Options({
  name: "AddUpdateVotingWindowModal",
  emits: ['cancel', 'confirm'],
  components: {
    VueFinalModal,
    ClipboardCheckIcon,
    flatPickr,
    Loader
  }
})
export default class AddUpdateVotingWindowModal extends Vue {   

    private startTime: string | null = null
    private closingTime: string | null = null
    private active: boolean = false
    private loading = false

    @Prop() votingWindow: VotingWindow | null = null

    @Watch('votingWindow')
    updateParams() {
      this.startTime = this.votingWindow?.openingTime ?? null
      this.closingTime = this.votingWindow?.closingTime ?? null
      this.active = this.votingWindow?.active ?? false
    }

    created() {
      this.updateParams()
    }

    get title() {
      if (this.votingWindow) {
        return "Edit Voting window"
      } else {
        return "Create Voting window"
      }
    }

    async savePressed(close: any) {

        const startTime = this.startTime
        const closingTime = this.closingTime

        if (!startTime || !closingTime) {
          return;
        }

        //Convert to UTC time
        const startTimeAtUTC = moment(startTime, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss')
        const closingTimeAtUTC = moment(closingTime, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss')

        if (!startTimeAtUTC || !closingTimeAtUTC) {
          return;
        }

        this.loading = true
        const appId = this.$route.params.appId as string
        let window: VotingWindow | null = null
        try {

            if (this.votingWindow) {
                window = (await AdminAPI.updateWindowForAppId(appId, this.votingWindow.id, {
                    openingTime: startTimeAtUTC,
                    closingTime: closingTimeAtUTC,
                    active: this.active
                })).data
            } else {
                window = (await AdminAPI.createWindowForAppId(appId, {
                    openingTime: startTimeAtUTC,
                    closingTime: closingTimeAtUTC,
                    active: this.active,
                    
                })).data
            }
        } catch (error) {
            console.log(error)
        }
        this.loading = false
        console.log(window)
        this.$emit('confirm', window, close)
    }
    
    formatDate(date: string, format: string): string {
    // locale can also be used
      return moment(date).format(format);
    }

    parseDate(datestr: string, format: string): Date {
      return moment(datestr, format, true).toDate();
    }
}   

