
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";


@Options({
  name: "Button"
})
export default class Button extends Vue {   
  @Prop({ type: String, require: true }) readonly title!: string
  @Prop({ type: Boolean, default: true }) readonly primary!: boolean
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean

  getClasses(){
    if (this.primary) {
      return ['bg-indigo-600', 'border-transparent', 'text-white', 'hover:bg-indigo-700' ]
    } else {
      return ['bg-white', 'border-gray-300', 'text-gray-700', 'hover:bg-gray-50']
    }
  }
  
}
