
import { AdminAPI } from '@/api'
import { Options, Vue} from 'vue-class-component'
import AttributeEditCreateDetailsComponent from '@/components/AttributeEditCreateDetailsComponent.vue'
import { AttributeMultiSelectOption, AttributeType } from '@/types/attributes'
import { Contestant, Round, RoundResultsModalMode } from '@/types/types'
import { RoundResultPayload } from '@/types/payloads'
import Loader from '@/components/Loader.vue'
import Modal from '@/components/Modal.vue'

@Options({
  components: {
    AttributeEditCreateDetailsComponent,
    Loader,
    Modal
  }
})
export default class AppRoundResults extends Vue {

  private loading: Boolean = false
  private error: Boolean = false
  private showModal: Boolean = false
  private modalType: RoundResultsModalMode = RoundResultsModalMode.CONTESTANT_CROSSOVER
  private contestants: Contestant[] = []
  private round: Round | null = null

  async mounted() {
    this.loadData()
  }

  async loadData() {
    const appId = this.$route.params.appId as string
    const roundId = this.$route.params.roundId as string
    try {
      const parsedRoundId = parseInt(roundId)
      this.loading = true
      const rounds = (await AdminAPI.getRoundsForAppId(appId)).data

      const foundRound = rounds.find( (round) => parsedRoundId == round.id)
      if (foundRound) {
        this.round = foundRound
        this.contestants = foundRound.contestants
      } 

      //Ensure we at least have the round as per query param

    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  }
  get title() {
    return `Create result for ${this.round?.name}`
  }

  async savePressed(changes: {key: string, value: any }[]) {
    let payload: {[key:string]: any}  = {}
    changes.forEach( obj => 
      payload[obj.key] = obj.value
    )

    const winningContestants: number[] = payload["winningContestants"] ?? []
    const eliminatedContestants: number[] = payload["eliminatedContestants"] ?? []

    //Check we dont have a contestant in both lists

    const intersectionArray = winningContestants.filter(value => eliminatedContestants.includes(value));

    if (intersectionArray.length != 0) {
      //We have duplicates
      //Show Alert
      this.showModalForType(RoundResultsModalMode.CONTESTANT_CROSSOVER)
      return
    }

    const resultPayload: RoundResultPayload = {
      winningContestants: winningContestants,
      eliminatedContestants: eliminatedContestants,
      winnersResultType: payload['winnersResultType']
    }

    this.loading = true
    const appId = this.$route.params.appId as string
    try {
      const roundId = this.$route.params.roundId as string
      const parsedRoundId = parseInt(roundId)
      await AdminAPI.setRoundResultForRound(appId, parsedRoundId, resultPayload)
      
      //Show success
      this.showModalForType(RoundResultsModalMode.SUCCESS)
    } catch (err) {
      console.error(err)
      this.showModalForType(RoundResultsModalMode.ERROR)
    } finally {
      this.loading = false
    }
  }

  cancelConfirmed() {
    this.$router.go(-1)
  }

  getAttributesForRoundresult() {

    //Get contestants eligible to vote
    const options: AttributeMultiSelectOption[] = this.contestants.filter( contestant => this.round?.contestantsIneligibleForVote.indexOf(contestant.id) == -1 ).map((contestant) => {
      return {value: contestant.id, title: contestant.name, imageUrl: contestant.profilePhoto}
    })

    const options2: AttributeMultiSelectOption[] = this.contestants.filter( contestant => this.round?.contestantsIneligibleForVote.indexOf(contestant.id) == -1 ).map((contestant) => {
      return {value: contestant.id, title: contestant.name, imageUrl: contestant.profilePhoto}
    })

    const options3: AttributeMultiSelectOption[] = [
      {value: 'WINNER', title: 'Winner'},
      {value: 'SAFE', title: 'Safe'},
    ]

    return [
      {type: AttributeType.MULTI_SELECT, required: false, title: "Winning Contestants:", value: [], placeholder: "Select winners", key: "winningContestants", options: options},
      {type: AttributeType.SINGLE_SELECT, required: true, title: "Mark winners as:", value: '', placeholder: "Select one", key: "winnersResultType", options: options3},
      {type: AttributeType.MULTI_SELECT, required: false, title: "Eliminated Contestants:", value: [], placeholder: "Select eliminated", key: "eliminatedContestants", options: options2},
    ]
  }

  modalConfirm() {
    this.showModal = false
    if (this.modalType == RoundResultsModalMode.SUCCESS){
      this.$router.go(-1)
    }
  }

  modalCancel(close: any) {
    close()
  }

  showModalForType(type: RoundResultsModalMode) {
    this.modalType = type
    this.showModal = true
  }

  get modalMessage() {
    switch (this.modalType) {
      case RoundResultsModalMode.CONTESTANT_CROSSOVER:
        return "A contestant cannot be both eliminated and winning"
      case RoundResultsModalMode.ERROR:
        return "An error occured while saving your changes"
      case RoundResultsModalMode.SUCCESS:
        return "Results set"
      default:
        return ""
    }
  }

  get modalDestructive() {
      switch (this.modalType) {
        case RoundResultsModalMode.SUCCESS:
          return false
        default:
          return true        
      }
  }

  get modalTitle() {
    switch (this.modalType) {
      case RoundResultsModalMode.SUCCESS:
        return "Success"
      default:
          return "Error"
    }
  }

  contestantNamesForIds(contestantIds: number[]) {
    return this.contestants.filter( contestant => contestantIds.indexOf(contestant.id) !== -1 ).map(contestant => contestant.name).join(', ')
  }

  contestantNamesForIdsNotIn(contestantIds: number[]) {
    return this.contestants.filter( contestant => contestantIds.indexOf(contestant.id) === -1 ).map(contestant => contestant.name).join(', ')
  }

  mapTypeToText(resultType: string) {
    switch (resultType) {
      case 'SAFE':
        return "Safe"
      case 'WINNER':
        return 'Winning'
    }
  }

}
