import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueLogger from 'vuejs3-logger';
import store from '@/store'
// @ts-ignore
import { vfmPlugin } from 'vue-final-modal'
import './main.css'
import VueApexCharts from "vue3-apexcharts";

const isProduction = process.env.NODE_ENV === 'production';
 
const options = {
    isEnabled: true,
    logLevel : isProduction ? 'error' : 'debug',
    stringifyArguments : false,
    showLogLevel : true,
    showMethodName : true,
    separator: '|',
    showConsoleColors: true
};


createApp(App)
    .use(router)
    .use(VueLogger, options)
    .use(store)
    .use(vfmPlugin)
    .use(VueApexCharts)
    .mount('#app')
