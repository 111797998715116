
import { Options, Vue } from "vue-class-component";
import { Prop } from 'vue-property-decorator'
import AttributeEditCreateDetailsComponent from '@/components/AttributeEditCreateDetailsComponent.vue'
import { AttributeMode, Contestant, Mentor } from "@/types/types";
import { AttributeMultiSelectOption, AttributeState, AttributeType } from "@/types/attributes";
import { MentorPayload } from "@/types/payloads";
import { AdminAPI } from "@/api";


@Options({
  name: "MentorEditComponent",
  components: {
    AttributeEditCreateDetailsComponent
  },
  emits: ['detailsComponentDidComplete', 'update:drawerDismissable', 'dismissSlideOver']
})
export default class MentorEditComponent extends Vue {   

  @Prop() mentorToView: Mentor | null = null
  @Prop({require: true}) mode: AttributeMode = AttributeMode.CREATE
  @Prop({type: Boolean, default: true }) drawerDismissable: Boolean = true
    
  private loading: boolean = false
  private error: boolean = false

  private contestants: Contestant[] = []
  async mounted() {
      const appId = this.$route.params.appId as string
      this.contestants = (await AdminAPI.getContestantsForAppId(appId)).data

  }

  get titleText() {
    if (this.mentorToView) {
      return this.mentorToView.name
    } else {
      return "Create Mentor"
    }
  }

  get subtitleText() {
    if (this.mentorToView) {
      return "View and edit"
    } else {
      return null
    }
  }

  savePressed(changes: {key: string, value: any }[]) {
    let payload: {[key:string]: any}  = {}
    changes.forEach( obj => 
      payload[obj.key] = obj.value
    )

    const updatedPayload = payload as MentorPayload

    switch (this.mode) {
      case AttributeMode.CREATE:
        this.createMentor(updatedPayload)
        return;
      case AttributeMode.VIEW_EDIT:
        this.updateMentor(updatedPayload)
        return;
    }
  }

  async createMentor(payload: MentorPayload) {

    this.loading = true
    this.error = false
    const appId = this.$route.params.appId as string
    try {
      await AdminAPI.createMentorForAppId(appId, payload)
      this.$emit('detailsComponentDidComplete')
    } catch (err) {
      console.error(err)
      this.error = true
    } finally {
      this.loading = false
    }
  }

  async updateMentor(payload: MentorPayload) {
    const mentorId = this.mentorToView?.id

    if (!mentorId) {
      this.error = true
      return
    }
    
    this.loading = true
    const appId = this.$route.params.appId as string
    try {
      await AdminAPI.updateMentorForAppId(appId, mentorId, payload)
      this.$emit('detailsComponentDidComplete')
    } catch (err) {
      console.error(err)
      this.error = true
    } finally {
      this.loading = false
    }

}

  getAttributesForContestant(): AttributeState[] {
    
    const options: AttributeMultiSelectOption[] = this.contestants.map((contestant) => {
      return  {value: contestant.id, title: contestant.name, imageUrl: contestant.profilePhoto}
    })

    const currentMenteeIds = this.mentorToView?.contestants.map( contestant => contestant.id ) ?? []

    return [
      {type: AttributeType.IMAGE, required: true, title: "Image", value: this.mentorToView?.profilePhoto, placeholder: "Profile Photo", key: "profilePhoto"},
      {type: AttributeType.TEXT, required: true, title: "First Name", value: this.mentorToView?.firstName, placeholder: "Enter First name", key: "firstName"},
      {type: AttributeType.TEXT, required: true, title: "Last Name", value: this.mentorToView?.lastName, placeholder: "Enter Last name", key: "lastName"},
      {type: AttributeType.LONG_TEXT, required: true, title: "Description", value: this.mentorToView?.description, placeholder: "Enter Description", key: "description"},
      {type: AttributeType.LONG_TEXT, required: true, title: "Description Irish", value: this.mentorToView?.descriptionIrish, placeholder: "Enter Description in Irish", key: "descriptionIrish"},
      {type: AttributeType.TEXT, required: false, title: "Twitter Handle", value: this.mentorToView?.twitterHandle, placeholder: "Enter Twitter Handle", key: "twitterHandle"},
      {type: AttributeType.MULTI_SELECT, required: false, title: "Contestants", value: currentMenteeIds, placeholder: "Select options", key: "contestants", options: options},
    ]
  }
  
}

